<template>
    <div class="container">
        <div>
            <h1 class="dense">{{ title }}</h1>
            <h2 class="dense">{{ message }}</h2>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import formMixin from "@/mixins/formMixin";

export default Vue.extend({
    name: "Error",
    components: {},
    mixins: [formMixin],
    props: {
        errorMessage: String,
    },
    data: () => {
        return {
            title: '',
            message: '',
        };
    },
    mounted() {
        try {
            const message = this.$props.errorMessage ?? '';
            const code = parseInt(message.replaceAll(/\D/g, ''));
            if (!Number.isInteger(code) || code < 400) {
                this.title = this.$t("message.error.unknown");
            } else if (code >= 400 && code < 500) {
                this.title = this.$t("message.error.notFound");
            } else if (code >= 500) {
                this.title = this.$t("message.error.serverError");
            }
            this.message = code ? 'Error Code: ' + code : '';
        } catch (e) {
            console.error('error message decoding failed');
        }
    },
});
</script>
<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>

