import Cookies from "js-cookie";
import { getJwt, setJwt, jwtHealthCheck, bodyIfOk, removeJwt } from "./http";


// TODO: Check JWT validity. Redirect to SMS page if expired. Set timer for it if still valid.
export const checkForJwt = async <S = void>(onSuccess: () => S, plainVpNr?: string, kundeId?: string) => {
    const currentJwt = getJwt() || Cookies.get('JWT');
    const loc = new URL(location.href);
    const qsParam = loc.searchParams.get("d");
    const entryLinkParam = loc.searchParams.get("v"); // i.e. encoded target broker data
    const forceFreshJwt = !!entryLinkParam || !!plainVpNr;
    let jwtIsHealthy = false;

    if (!forceFreshJwt && currentJwt) {
        jwtIsHealthy = jwtHealthCheck(currentJwt);
        if (jwtIsHealthy) {
            setJwt(currentJwt);
            return Promise.resolve();
        } else {
            removeJwt();
        }
    }

    // jwt from backend
    const params = new URLSearchParams();
    if (qsParam) {
        params.append('d', qsParam);
    }

    if (entryLinkParam) {
        params.append('v', entryLinkParam);
    } else {
        if (plainVpNr) {
            params.append('vp_nr', plainVpNr);
        }
        if (kundeId) {
            params.append('kunde_id', kundeId);
        }
    }

    const dataJwtUrl = '/data/jwt' + (
        '' === params.toString() ? '' : '?' + params.toString()
    );

    const headers = new Headers();
    if (currentJwt && jwtIsHealthy) {
        headers.append('Authorization',  `Bearer ${currentJwt}`)
    } else {
        removeJwt();
    }

    return fetch(dataJwtUrl, {headers})
        .then(bodyIfOk)
        .then(jwt => {
            setJwt(jwt);
            return Promise.resolve();
        })
        .then(() => onSuccess());
}
