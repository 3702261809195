import * as convert from 'color-convert';

type Hex = `#${string}`;
type RgbValues = [number, number, number];

/** Für einfaches HSL-Format wie "hsl(50 80% 40%)" bzw. "hsl(50deg 80% 40%)" */
export const hslToHex = (hslColor: string): Hex => {
  const arrStr = hslColor.match(/\d+/g);
  if (3 !== arrStr?.length) return '#FFF';
  const arr = arrStr.map(Number);
  return `#${convert.hsl.hex([arr[0], arr[1], arr[2]])}`;
};

/*
* Lesbarkeit von Text bestimmen. Nach WCAG der WAI des W3C:
* https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html
* Für normale Textgröße: Kontrastverhältnis von mindestens 4.5:1 (Level AA)
* bzw. 7:1 (Level AAA)
* */
export class TextReadableCheck {

  public isReadableAA: boolean;
  public isReadableAAA: boolean;
  public contrastRatio: number;
  public hex1: Hex;
  public hex2: Hex;

  private readonly minimumAA = 4.5;
  private readonly minimumAAA = 7;

  constructor(hex1: Hex, hex2: Hex) {
    this.hex1 = hex1;
    this.hex2 = hex2;
    this.contrastRatio = this.getContrastRatio(hex1, hex2);
    this.isReadableAA = this.minimumAA <= this.contrastRatio;
    this.isReadableAAA = this.minimumAAA <= this.contrastRatio;
  }

  private getContrastRatio(hex1: Hex, hex2: Hex): number {
    const rgb1 = this.hexToRgb(hex1);
    const rgb2 = this.hexToRgb(hex2);
    const luminance1 = this.rgbToLuminance(rgb1);
    const luminance2 = this.rgbToLuminance(rgb2);
    const lighter = Math.max(luminance1, luminance2);
    const darker = Math.min(luminance1, luminance2);
    return (lighter + 0.05) / (darker + 0.05);
  }

  private rgbToLuminance([r, g, b]: RgbValues): number {
    const [rNorm, gNorm, bNorm] = [r, g, b].map(c => {
      c = c / 255;
      return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    });

    return 0.2126 * rNorm + 0.7152 * gNorm + 0.0722 * bNorm;
  }

  private hexToRgb(hex: Hex): RgbValues {
    return convert.hex.rgb(hex);
  }
}
