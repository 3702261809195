import Vue from 'vue';
import Vuex from "vuex";
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import HighchartsVue from 'highcharts-vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import { messages } from './locales/locales';
import mitt from 'mitt';
import VueMatomo from 'vue-matomo';

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(HighchartsVue);
Vue.use(VueI18n);
Vue.use(VueRouter);

if (process.env.NODE_ENV === 'production') {
    Vue.use(VueMatomo, {
        host: 'https://foo.matomo.cloud',
        trackerFileName: 'matomo.php',
        trackerUrl: 'https://foo.matomo.cloud/matomo.php',
        trackerScriptUrl: 'https://cdn.matomo.cloud/foo.matomo.cloud/matomo.js',
        requireConsent: false,
        disableCookies: true,
        siteId: 12,
        router: router,
    });
}

const settingsStore = new Vuex.Store({
  state: () => ({
    stammdatenVermittler: {},
    dienstleistung: "",
    investmentConstraints: {},
    initPage: "",
    settings: {},
  }),
  mutations: {
    setStammdatenVermittler: (state, stammdatenVermittler) =>
      state.stammdatenVermittler = stammdatenVermittler,
    setDienstleistung: (state, dienstleistung) => state.dienstleistung = dienstleistung,
    setInvestmentConstraints:
      (state, constraints) => state.investmentConstraints = constraints,
    setInitPage: (state, initPage) => state.initPage = initPage,
    setSettings: (state, settings) => state.settings = settings,
  },
});

const i18n = new VueI18n({
  locale: 'de',
  messages
});
Vue.prototype.$emitter = mitt();

new Vue({
  router,
  vuetify,
  i18n,
  store: settingsStore,
  render: h => h(App)
}).$mount('#app');

export {i18n};
