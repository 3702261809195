import Vue from "vue";
import {validate} from "@/utils/validation";
import {store} from "../stores/savedValuesStore";
import {setSavedData, postData, handleSuccess, getSavedData, jumpTo, removeJwt, onDepotCreated} from "../utils/http";
import {extractData} from "../stores/savedValuesStore";
import * as lodash from "lodash";


const formMixin = {
    computed: {
        formValid() {
            return !validate(this.formData);
        },
        savedValue() {
            return store.savedValue;
        },
        size() {
            const size = {xs:'x-small',sm:'x-small',lg:'large',xl:'x-large'}[this.$vuetify.breakpoint.name];
            return size ? { [size]: true } : {}
        },
    },

    methods: {
        restoreSavedData: async function() {
            if (lodash.keys(this.savedValue).length > 0) {
                setSavedData(this.savedValue, this.formData);
            } else if ("targetUrl" in this && !!this.targetUrl) {
                if ("loading" in this) {
                    this.loading = true; // FIXME TODO Doesn’t work, make it work.
                }
                await getSavedData('/pages' + this.targetUrl).then(res => res.json()).then(
                    res => {
                        const data = res || {};
                        lodash.keys(data).forEach(
                            key => Vue.set(this.savedValue, key, data[key])
                        );
                        setSavedData(this.savedValue, this.formData);
                        if ("loading" in this) {
                            this.loading = false;
                        }
                    }
                );
            }
        },

        /**
         * @param {boolean} forwards
         * @param {boolean} [createDepot]
         * */
        onNavigate: function (forwards, createDepot) {
            Vue.prototype.$emitter.emit('submitBegin');
            postData(
                this.targetUrl,
                JSON.stringify(extractData(this.formData)),
                forwards ? "forward" : "back"
            )
                .then((res) => {
                    if (createDepot) onDepotCreated();

                    this.dataSent = true;
                    Vue.prototype.$emitter.emit('submitSuccess');
                    handleSuccess(res, forwards, this.formData, this.$refs?.form);
                    // Reenable button in case handleSuccess did not cause nagivation
                    // (i. e. in case the backend sent validation errors):
                    Vue.prototype.$emitter.emit('submitError'); // FIXME: Make custom event.
                })
                .catch(e => {
                    Vue.prototype.$emitter.emit('submitError', e);
                })
        },

        onReset: function () {
            removeJwt();
            jumpTo("/Beratungsende");
        },

        roundToXDecimals: function (value, decimals = 2) {
            if ('number' !== typeof value) return value;
            return Math.round((value + Number.EPSILON) * 10 ** decimals) / 10 ** decimals;
        },

        mainFormatPercent: function (value) {
            return String(this.roundToXDecimals(100 * value))
                .replace('.', ',');
        },

        mainFormatCurrency: function (content) {
            // remove all non-digits and non commas
            content = content.toString().replaceAll(/[^\d^,]*/ig, '');
            // only allow one comma
            if (content.split(',').length > 2) {
                let i = content.indexOf(',');
                content = content.substring(0, i + 1) + content.substring(i + 1, content.length).replaceAll(/,/ig, '');
            }
            // remove leading zero if found
            content = '0' === content[0] && content.length > 1 ? content.substring(1, content.length) : content;
            // insert dots every three digits
            let i = content.indexOf(',') !== -1 ? content.indexOf(',') : content.length;
            // read digits before the comma
            let digits = content.substring(0, i);
            // add dots where needed
            digits = digits.split('');
            for (let j = digits.length - 3; j >= 1; j = j - 3) {
                digits.splice(j, 0, '.');
            }
            digits = digits.join('');
            // concat strings (from commat to the number of needed decimals)
            let decimals = i < content.length ? ',' + content.substring(i + 1, i + 1 + 2) : '';
            content = digits + decimals;
            return content;
        },

        $c: function (currencyAmount) {
            return this.$n(
                currencyAmount,
                {
                    style: 'currency', currency: 'EUR'
                }
            );
        },

        forceRevalidate() {
            this.formData.__ob__.dep.notify();
        },
    },

    mounted: async function () {
        return this.restoreSavedData();
    },
    /*
    beforeRouteLeave(to, from, next) {
        if (this.dataSent) {
            next();
        } else {
            const isBack = routerStore.getters.previousRoute.path === to.path;
            this.onNavigate(!isBack);
        }
    },

   */
}

export default formMixin;
