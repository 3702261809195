<template>
    <form ref="downloadForm"
          method="POST"
          v-bind:action="selectedDocumentAction"
          target="_blank"
          :class="{'v-btn': isBtn}"
    >
        <v-btn
            v-if="isBtn"
            text
            target="_blank"
            @click.prevent="downloadDocument()"
            :outlined="outlined"
            color="primary"
        >
            <slot>Download</slot>
        </v-btn>
        <a
            v-else
            href="#"
            class="text-decoration-underline text--secondary"
            @click.prevent="downloadDocument()"
            :style="'color:' + color + ' !important;'"
        >
            <slot>Download</slot>
        </a>

        <input type="hidden" name="jwt" v-bind:value="jwt" />
    </form>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    name: 'downloadFormWidget',
    props: {
        jwt: {
            type: String,
            required: true,
        },
        targetDocument: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: () => 'pdf',
            validator(value) {
                return ['pdf', 'zip', "application/pdf", "application/zip"].includes(value);
            }
        },
        color: {
            type: String,
            default: () => 'var(--marvin-col)',
        },
        isBtn: Boolean,
        outlined: {
          type: Boolean,
          default: () => false,
        }
    },
    computed: {
        selectedDocumentAction() {
            return `/data/${this.targetDocument}?type=${this.type}`;
        },
    },
    methods: {
        downloadDocument: function() {
            this.$nextTick(() => {
                this.$refs['downloadForm'].submit();
            });
        },
    }
})
</script>
