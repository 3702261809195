<template>
<div class="background-overlay">
  <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
</div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "LoadingOverlay",
});
</script>

<style lang="scss" scoped>
.background-overlay {
  background-color: #333333;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.85;
  z-index: 1040;
  align-items: center;
  justify-content: center;
  display: flex;
}

v-progress-circular {
  z-index: 1050;
}
</style>