import Vue from 'vue';
import VueRouter, {Route, RouteConfig} from 'vue-router';
import Vuex from 'vuex';

Vue.use(Vuex)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        props: true,
        component: () => import('../views/Home.vue'),
    },
    {
        // no component, no redirect – path is handled in App.vue
        path: '/vp/:vpKundenNr',
        name: 'InitVpSessionByKundenNr',
    },
    {
        // no component, no redirect – path is handled in App.vue
        path: '/vp/:vpKundenNr/kunde/:kundenId',
        name: 'InitVpSessionByKundenNrAndKundenId',
    },
    {
        path: '/bestandskunde',
        name: 'bestandskunde',
        props: true,
        component: () => import('../views/Bestandskunde.vue'),
    },
    {
        path: '/bestandskundeConfirm',
        name: 'bestandskundeConfirm',
        props: true,
        component: () => import('../views/daten/ConfirmAccount.vue'),
    },
    {
        path: '/beratung',
        component: () => import('../views/Beratung.vue'),
        props: true,
        // TODO: check why used as children but the path is not nested when using /
        children: [
            {
                path: '/dienstleistungsauswahl',
                name: 'Dienstleistungsauswahl',
                props: true,
                component: () => import('../views/kapitalanlage/Dienstleistungsauswahl.vue'),
            },
            {
                path: '/anlageziel',
                name: 'Anlageziel',
                component: () => import('../views/kapitalanlage/Anlageziel.vue')
            },
            {
                path: '/anlagebetrag',
                name: 'AnlagebetraegeBetrag',
                component: () => import('../views/kapitalanlage/AnlagebetraegeBetrag.vue')
            },
            {
                path: '/zeitraum',
                name: 'Zeitraum',
                component: () => import('../views/kapitalanlage/Zeitraum.vue')
            },
            {
                path: '/risikobereitschaft',
                name: 'Risikobereitschaft',
                component: () => import('../views/kapitalanlage/Risikobereitschaft.vue')
            },
            {
                path: '/erfahrungen',
                name: 'Erfahrungen',
                component: () => import('../views/kapitalanlage/Erfahrungen.vue')
            },
            {
                path: '/erfahrungenUmfang',
                name: 'ErfahrungenUmfang',
                component: () => import('../views/kapitalanlage/ErfahrungenUmfang.vue')
            },
            {
                path: '/wertpapiererfahrung',
                name: 'erfahrungwertpapier',
                component: () => import('../views/kapitalanlage/Wertpapiererfahrung.vue')
            },
            {
                path: '/liquiditaet',
                name: 'Monatliche Liquidität',
                component: () => import('../views/kapitalanlage/MonatlicheLiquiditaet.vue')
            },
            {
                path: '/freiesvermoegen',
                name: 'Freies Vermögen',
                component: () => import('../views/kapitalanlage/FreiesVermoegen.vue')
            },
            {
                path: '/produktauswahl',
                name: 'Produktauswahl',
                component: () => import('../views/kapitalanlage/Produktauswahl.vue')
            },
            {
                path: '/anlagevorschlag',
                name: 'Anlagevorschlag',
                component: () => import('../views/kapitalanlage/Anlagevorschlag.vue')
            },
            {
                path: '/createAccount',
                name: 'CreateAccount',
                component: () => import('../views/daten/CreateAccount.vue')
            },
            {
                path: '/confirmAccount',
                name: 'ConfirmAccount',
                component: () => import('../views/daten/ConfirmAccount.vue')
            },
            {
                path: '/depotinhaber',
                name: 'Depotinhaber',
                component: () => import('../views/daten/Depotinhaber.vue')
            },
            {
                path: '/adresse',
                name: 'Adresse',
                component: () => import('../views/daten/Adresse.vue')
            },
            {
                path: '/geburtsdaten',
                name: 'Geburtsdaten',
                component: () => import('../views/daten/Geburtsdaten.vue')
            },
            {
                path: '/steuerpflicht',
                name: 'Steuerpflicht',
                component: () => import('../views/daten/Steuerpflicht.vue')
            },
            {
                path: '/bankverbindung',
                name: 'Bankverbindung',
                component: () => import('../views/daten/Bankverbindung.vue')
            },
            {
                path: '/ausweisdaten',
                name: 'Ausweisdaten',
                component: () => import('../views/daten/Ausweisdaten.vue')
            },
            {
                path: '/fragen',
                name: 'Fragen',
                component: () => import('../views/abschluss/Fragen.vue')
            },
            {
                path: '/vollstaendigkeit',
                name: 'Vollstaendigkeit',
                component: () => import('../views/abschluss/Vollstaendigkeit.vue')
            },
            {
                path: '/confirmAnlageberatung',
                name: 'confirmAnlageberatung',
                component: () => import('../views/abschluss/Confirmanlageberatung.vue')
            },
            {
                path: '/depotEroeffnen',
                name: 'DepotEroeffnen',
                component: () => import('../views/abschluss/DepotEroeffnen.vue')
            },
            {
                path: '/confirmdepoteroeffnung',
                name: 'Confirmdepoteroeffnung',
                component: () => import('../views/abschluss/Confirmdepoteroeffnung.vue')
            },
            {
                path: '/ausweisdatenUeberpruefen',
                name: 'ausweisdatenUeberpruefen',
                component: () => import('../views/abschluss/Ausweisdatenueberpruefen.vue')
            },
            {
                path: '/postidentLater',
                name: 'postidentLater',
                component: () => import('../views/abschluss/postidentLater.vue')
            },
            /* Spezielle Seiten (error, info, ...)*/
            {
                path: '/fehler',
                name: 'Fehler',
                component: () => import('../views/error/Fehler.vue')
            },
            {
                path: '/beratungsende',
                name: 'Beratungsende',
                component: () => import('../views/error/Beratungsende.vue')
            },
            {
                path: '/inbearbeitung',
                name: 'InBearbeitung',
                component: () => import('../views/error/InBearbeitung.vue')
            },
            {
                path: '/fehlerEingabe',
                name: 'FehlerEingabe',
                component: () => import('../views/error/FehlerEingabe.vue')
            },
            {
                path: '/geeignetheitserklaerung',
                name: 'Geeignetheitserklaerung',
                component: () => import(/* webpackChunkName: "geeignetheitserklaerung" */ '../views/Geeignetheitserklaerung.vue')
            },
            {
                path: '/',
                redirect: '/anlageziel'
            },
            {
                path: '/*',
                name: 'Error',
                component: () => import('../views/error/Error.vue')
            },
        ]
    },
];

export const routerStore = new Vuex.Store({
    state: {
        routerHistory: [] as Route[],
    },
    getters: {
        previousRoute: (state) => {
            const historyLen = state.routerHistory.length;
            if (historyLen === 0) return null;
            return state.routerHistory[historyLen - 1];
        },
    },
});

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        const fromHistory = Boolean(savedPosition);

        if (fromHistory && routerStore.state.routerHistory.length > 0) {
            routerStore.state.routerHistory.splice(-1, 1);
        } else {
            routerStore.state.routerHistory.push(from);
        }

        return savedPosition || {x: 0, y: 0};
    },
});

export default router;
